<template>
	<SiteElementSearchBar
		:placeholder-text="props.data.placeholderText"
		:no-results-text="props.data.noResultsText"
		:fill-color="props.data.fillColor"
		:fill-color-hover="props.data.fillColorHover"
		:text-and-icon-color="props.data.textAndIconColor"
		:text-and-icon-color-hover="props.data.textAndIconColorHover"
		:font-family="props.data.fontFamily"
		:border-color="props.data.borderColor"
		:border-color-hover="props.data.borderColorHover"
		:border-radius="props.data.borderRadius"
		:is-loading="isLoading"
		:result-item-hover-color="props.data.resultItemHoverColor"
		:results="computedResults"
		:search-term="searchTerm"
		:site-id="siteId"
		:translations="ecommerceTranslations"
		@update:search-term="updateSearchTerm({ newValue: $event })"
		@is-dropdown-open="toggleSearchDropdown"
	/>
</template>

<script setup lang="ts">
import {
	ref,
	computed,
} from 'vue';
import SiteElementSearchBar from '@zyro-inc/site-modules/components/SiteElementSearchBar.vue';
import { SEARCH_INDICE_PRODUCTS } from '@zyro-inc/site-modules/constants';
import { debounce } from '@zyro-inc/site-modules/utils/debounce';
import { SITE_PRODUCT_SELECTION_TYPE_LOWEST } from '@zyro-inc/site-modules/constants/ecommerce';
import {
	getIsProductInStock,
	isProductPriceRangeShown,
	getLowestPriceVariant,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import priceFormatter from '@zyro-inc/site-modules/utils/ecommerce/priceFormatter';

import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import { search } from '@zyro-inc/site-modules/api/PublicApi';
import {
	ElasticSearchVariant,
	ElasticSearchResult,
	SiteElementSearchItem,
} from '@zyro-inc/site-modules/types';
import { useSearchElementDropdown } from '@zyro-inc/site-modules/utils/useSearchElementDropdown';

const TIMEOUT_MS_SEARCH_DEBOUNCE = 400;

const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	blockId: {
		type: String,
		required: true,
	},
});

const {
	meta,
	siteId,
	ecommerceShoppingCart,
} = useSiteGlobal();

const ecommerceTranslations = computed(() => ecommerceShoppingCart.value?.translations || {});

const { toggleSearchDropdown } = useSearchElementDropdown({
	blockId: props.blockId,
});
const searchTerm = ref('');
const isLoading = ref(false);
const results = ref<Array<ElasticSearchResult> | null>(null);
const storeId = computed(() => meta.value.ecommerceStoreId);

const computedResults = computed<Array<SiteElementSearchItem> | null>(() => {
	if (!results.value) {
		return null;
	}

	return results.value.map((result: ElasticSearchResult) => {
		const variant: ElasticSearchVariant = result.site_product_selection === SITE_PRODUCT_SELECTION_TYPE_LOWEST
			? getLowestPriceVariant({
				variants: result.variants,
			}) as ElasticSearchVariant
			: result.variants[0];
		const {
			amount,
			sale_amount: saleAmount,
			currency_decimal_digits: currencyDecimalDigits,
			currency_template: currencyTemplate,
			manage_inventory: isInventoryManaged,
		} = variant;
		const currency = {
			decimal_digits: currencyDecimalDigits,
			template: currencyTemplate,
		};
		const href = `/product-redirect/${result.id}`;
		const pricePrefix = amount && isProductPriceRangeShown(result) ? `${ecommerceTranslations.value.from} ` : '';
		const price = amount ? `${pricePrefix}${priceFormatter(saleAmount || amount, currency)}` : '';
		const oldPrice = saleAmount ? priceFormatter(amount, currency) : null;
		const isInStock = !isInventoryManaged || (!!amount && getIsProductInStock({
			product: result,
			variantsQuantity: result.variants,
		}));

		return {
			id: result.id,
			thumbnail: result.thumbnail,
			title: result.title,
			href,
			price,
			oldPrice,
			isInStock,
		};
	});
});

const searchHandler = debounce(async () => {
	isLoading.value = true;

	if (searchTerm.value && storeId.value) {
		results.value = await search({
			query: searchTerm.value,
			indices: [SEARCH_INDICE_PRODUCTS],
			storeId: storeId.value,
		});
	} else {
		results.value = null;
	}

	isLoading.value = false;
}, TIMEOUT_MS_SEARCH_DEBOUNCE);

const updateSearchTerm = ({ newValue } : { newValue: string }) => {
	searchTerm.value = newValue;
	searchHandler();
};
</script>
