<template>
	<BlockBlogList
		:data="data"
		:block-id="blockId"
		:posts="blogPosts"
		:blog-categories="blogCategories"
		:blog-reading-time-text="blogReadingTimeText"
	/>
</template>

<script>
import {
	SYSTEM_LOCALE,
	PAGE_TYPE_BLOG,
} from '@zyro-inc/site-modules/constants';
import BlockBlogList from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogList.vue';
import {
	BLOG_POST_COVER_IMAGE_MAX_WIDTH,
	BLOG_POST_COVER_IMAGE_MAX_HEIGHT,
} from '@zyro-inc/site-modules/components/blocks/blog/constants';
import { getGridItemSrcset } from '@zyro-inc/site-modules/utils/getSrcsets';

import { defineComponent } from 'vue';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

export default defineComponent({
	name: 'BlockBlogListProviderUser',

	components: {
		BlockBlogList,
	},

	props: {
		blockId: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		currentLocale: {
			type: String,
			default: SYSTEM_LOCALE,
		},
	},
	setup() {
		const {
			blogReadingTimeText,
			siteId,
			pages,
			blogCategories,
			meta,
		} = useSiteGlobal();

		return {
			blogReadingTimeText,
			siteId,
			pages,
			blogCategories,
			meta,
		};
	},
	computed: {
		publishedBlogPages() {
			return Object.fromEntries(Object.entries(this.pages).filter(([, page]) => page.type === PAGE_TYPE_BLOG && !page.isDraft));
		},
		blogPosts() {
			const isCurrentLocaleDefaultLocale = this.currentLocale === this.meta.defaultLocale;

			return Object.fromEntries(Object.entries(this.publishedBlogPages)
				.map(([id, post]) => {
					const coverImageSrcset = getGridItemSrcset(
						post.coverImageOrigin,
						post.coverImagePath,
						this.siteId,
						{
							width: BLOG_POST_COVER_IMAGE_MAX_WIDTH,
							height: BLOG_POST_COVER_IMAGE_MAX_HEIGHT,
						},
					);

					return [
						id,
						{
							id,
							...post,
							coverImageSrcset,
							slug: `/${isCurrentLocaleDefaultLocale ? '' : `${this.currentLocale}/`}${post.slug}`,
						},
					];
				}));
		},
	},
});
</script>
