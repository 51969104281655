import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import * as Vue from 'vue';

import { addDocumentElementsByRoutePath } from '@zyro-inc/site-modules/utils/addDocumentElements';

import {
	dataQADirective,
	QA_DIRECTIVE_NAME,
} from '@zyro-inc/site-modules/directives/dataQaDirective';

import '@zyro-inc/site-modules/scss/global.scss';
import App from '@/App.vue';
import { fetchSiteData } from '@zyro-inc/site-modules/utils/fetchSiteData';
import router from '@/router';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

const {
	website,
	setWebsite,
} = useSiteGlobal();

if ('ResizeObserver' in window === false) {
	window.ResizeObserver = ResizeObserverPolyfill;
}

const app = Vue.createApp({
	mounted: () => {
		// 'site-engine-mount' custom event is used in prerender service
		// it notifies lambda that app is mounted and it could save the HTML output
		document.dispatchEvent(new Event('site-engine-mount'));
		// when all external dependencies are loaded, fire 'DOMContentLoaded', because some external scripts depend on it
		window.addEventListener('load', () => document.dispatchEvent(new Event('DOMContentLoaded')));
	},
	watch: {
		$route: {
			handler(route) {
				if (route && website.value) {
					addDocumentElementsByRoutePath({
						path: route.path,
						siteData: website.value,
					});
				}
			},
			deep: true,
		},
	},
	render: () => Vue.h(App),
})
	.use(router);

fetchSiteData().then((siteData) => {
	// Don't overwrite website if it's already set
	// Currently we have only 1 such condition - postMessage in builder preview sets website before this fetch
	if (siteData && !website.value) {
		addDocumentElementsByRoutePath({
			path: router.currentRoute.value.path,
			siteData,
		});

		setWebsite(siteData);
	}
}).finally(() => {
	app.directive(QA_DIRECTIVE_NAME, dataQADirective);
	app.mount('#app');
});
