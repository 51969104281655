<script setup lang="ts">
import {
	ref,
	onMounted,
	onBeforeUnmount,
} from 'vue';

import { useStickyTrigger } from '@zyro-inc/site-modules/use/useStickyTrigger';

const OBSERVER_OPTIONS = {
	threshold: 1,
};

const stickyTriggerRef = ref(null);
const observer = ref<IntersectionObserver | null>(null);
const { setIntersectingState } = useStickyTrigger();

onMounted(() => {
	observer.value = new IntersectionObserver(([{ isIntersecting }]) => {
		setIntersectingState(isIntersecting);
	}, OBSERVER_OPTIONS);

	if (!stickyTriggerRef.value) {
		return;
	}

	observer.value.observe(stickyTriggerRef.value);
});

onBeforeUnmount(() => {
	observer.value?.disconnect();
});
</script>

<template>
	<div
		ref="stickyTriggerRef"
		class="sticky-trigger"
	/>
</template>

<style lang="scss">
.sticky-trigger {
	position: relative;
	top: 0;
	z-index: -1;
	height: 1px;
	margin-bottom: -1px;
	pointer-events: none;
}
</style>
