const isEmptyObject = (obj) => Object.keys(obj).length === 0;

export const filterLanguageHiddenPages = (
	pages,
	currentDate,
	isAstro = true,
) => Object.fromEntries(
	Object.entries(pages)
		.map(([pageKey, pageValue]) => {
			if (!pageValue.isScheduled) {
				return [
					pageKey,
					pageValue,
				];
			}

			const { date } = pageValue;

			const publishDate = new Date(date).setHours(0, 0, 0, 0);
			const hasCurrentDateExceededPublishDate = publishDate <= currentDate;

			return [
				pageKey,
				{
					...pageValue,
					...(hasCurrentDateExceededPublishDate ? {
						isDraft: false,
						isScheduled: false,
					} : {
						isDraft: true,
					}),
				},
			];
		})
		// Omit draft blog pages when building for Astro
		.filter(((entry) => (!(isAstro && entry[1].isDraft))))
		.filter((entry) => !isEmptyObject(entry[1])),
);

export const filterHiddenPages = (languages, currentDate) => Object.fromEntries(
	Object.entries(languages).map(([key, value]) => {
		const pages = filterLanguageHiddenPages(value.pages, currentDate);

		return [
			key,
			{
				...value,
				pages,
			},
		];
	}),
);
