<template>
	<div>
		<RouterView />
	</div>
</template>

<script setup>

import {
	UPDATE_SITE_DATA,
	ADD_DOCUMENT_ELEMENTS,
	NAVIGATE_TO_PATH,
	PREVIEW_FRAME_MOUNTED,
} from '@zyro-inc/site-modules/constants/messageEvents';

import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useRouter } from 'vue-router';
import { addDocumentElementsByRoutePath } from '@zyro-inc/site-modules/utils/addDocumentElements';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { BUILDER_SUBDOMAINS } from '@zyro-inc/site-modules/components/metas/constants';

const router = useRouter();
const { setShoppingCartItems } = useEcommerceGlobal();
const { setWebsite } = useSiteGlobal();

/**
 * Listen for message events to allow setting data externally
 * Used for previews
 */
const handleWindowMessage = (event) => {
	const { data } = event;
	const isBuilderDomainOrigin = BUILDER_SUBDOMAINS.some((builderDomain) => event.origin.includes(builderDomain));
	const isOriginAllowed = import.meta.env.DEV || event.origin === window.origin || isBuilderDomainOrigin;

	if (typeof data !== 'object' || !isOriginAllowed) {
		return;
	}

	if (data.type === UPDATE_SITE_DATA) {
		setWebsite(data.payload.siteData);

		if (data.payload.path && data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}

		setShoppingCartItems([]);
	}

	// NAVIGATE_TO_PATH event is used by other internal services (site-position-service, screenshot-service)
	if (data.type === NAVIGATE_TO_PATH) {
		if (data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}
	}

	if (data.type === ADD_DOCUMENT_ELEMENTS) {
		addDocumentElementsByRoutePath({
			path: router.currentRoute.value.path,
			siteData: data.payload.siteData,
		});
	}
};

window.addEventListener('message', (event) => {
	handleWindowMessage(event);
});

window.parent.postMessage({
	type: PREVIEW_FRAME_MOUNTED,
}, '*');

</script>
