<template>
	<section
		:id="id"
		ref="blockRef"
		class="block"
		:class="[
			animationClass,
			{
				'block--footer': isBlockFooter,
				'block--desktop-hidden': isDesktopBlockHidden,
				'block--mobile-hidden': isMobileBlockHidden
			},
		]"
		:style="computedStyles"
	>
		<BlockBackground
			v-if="data.background"
			:overlay-opacity="data.background['overlay-opacity']"
			:type="data.background.current"
			:color="data.background.color"
			:src="backgroundSrc"
			:srcset="backgroundSrcSet"
			:is-eager="lcp.type === 'block-background' && lcp.id === id"
			:is-fixed="data.attachment === 'fixed'"
			:alt="data.background && data.background.alt"
		/>
		<BlockLayoutProviderUser
			v-if="data.type === BLOCK_TYPE_LAYOUT"
			:lcp="lcp"
			:data="data"
			:block-id="id"
			:components="siteElements"
			:current-locale="currentLocale"
			:is-cart-visible="isCartVisible"
			:site-language-pages="siteLanguagePages"
			:stripe-public-api-key="stripePublicApiKey"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
		<BlockBlogHeader
			v-if="data.type === BLOCK_TYPE_BLOG_HEADER"
			:data="data"
			:current-blog-page="currentBlogPage"
			:blog-categories="blogCategories"
			:blog-reading-time-text="blogReadingTimeText"
		/>
		<BlockBlogListProviderUser
			v-if="data.type === BLOCK_TYPE_BLOG_LIST"
			:data="data"
			:block-id="id"
			:current-locale="currentLocale"
		/>
		<BlockImageSlideshowProviderUser
			v-if="data.type === BLOCK_TYPE_IMAGE_SLIDESHOW"
			:data="data"
			:block-id="id"
			:website-id="websiteId"
		/>
		<BlockEcwidStoreProviderUser
			v-if="data.type === BLOCK_TYPE_ECWID"
			:data="data"
			:block-id="id"
			:current-locale="currentLocale"
		/>
		<BlockEcommerceProductProviderUser
			v-if="data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT"
			:lcp="lcp"
			:data="data"
			:block-id="id"
			:product-pages="productPages"
			:is-cart-visible="isCartVisible"
			:ecommerce-translations="ecommerceTranslations"
			:current-page-type="currentPageType"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
		<BlockEcommerceProductListProviderUser
			v-if="data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST"
			:lcp="lcp"
			:data="data"
			:block-id="id"
			:blocks="siteBlocks"
			:current-locale="currentLocale"
			:is-cart-visible="isCartVisible"
			:ecommerce-translations="ecommerceTranslations"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
	</section>
</template>

<script setup>
import {
	computed,
	onMounted,
	onBeforeUnmount,
	ref,
	watch,
} from 'vue';

import BlockBackground from '@zyro-inc/site-modules/components/blocks/BlockBackground.vue';
import BlockBlogHeader from '@zyro-inc/site-modules/components/blocks/blog/BlockBlogHeader.vue';
import {
	PAGE_TYPE_BLOG,
	SYSTEM_LOCALE,
	BLOCK_TYPE_LAYOUT,
	BLOCK_TYPE_BLOG_HEADER,
	BLOCK_TYPE_BLOG_LIST,
	BLOCK_TYPE_IMAGE_SLIDESHOW,
	BLOCK_TYPE_ECWID,
	BLOCK_TYPE_ECOMMERCE_PRODUCT,
	BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST,
	MEDIA_MOBILE_SCREEN_BREAKPOINT,
} from '@zyro-inc/site-modules/constants';
import { FULL_WIDTH } from '@zyro-inc/site-modules/utils/getGridItemSize';
import {
	getFullWidthSrcset,
	getOptimizedSrc,
} from '@zyro-inc/site-modules/utils/getSrcsets';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { parseCSSSides } from '@zyro-inc/site-modules/utils/parseCSSSides';
import BlockLayoutProviderUser from '@zyro-inc/site-modules/components/block-layout/BlockLayoutProviderUser.vue';
import BlockBlogListProviderUser from '@zyro-inc/site-modules/components/blocks/BlockBlogListProviderUser.vue';
import BlockEcommerceProductListProviderUser from '@zyro-inc/site-modules/components/blocks/BlockEcommerceProductListProviderUser.vue';
import BlockEcommerceProductProviderUser from '@zyro-inc/site-modules/components/blocks/BlockEcommerceProductProviderUser.vue';
import BlockEcwidStoreProviderUser from '@zyro-inc/site-modules/components/blocks/BlockEcwidStoreProviderUser.vue';
import BlockImageSlideshowProviderUser from '@zyro-inc/site-modules/components/blocks/BlockImageSlideshowProviderUser.vue';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

const MOBILE_FULL_HEIGT_THRESHOLD = 360;

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	lcp: {
		type: Object,
		default: () => ({}),
	},
	currentLocale: {
		type: String,
		default: SYSTEM_LOCALE,
	},
	style: {
		type: Object,
		default: () => ({}),
	},
	ecommerceTranslations: {
		type: Object,
		default: () => ({}),
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
	pageId: {
		type: String,
		required: true,
	},
	currentPageType: {
		type: String,
		default: 'default',
	},
	isInPreviewMode: {
		type: Boolean,
		default: false,
	},
	screenWidth: {
		type: Number,
		default: 0,
	},
});
const blockRef = ref(null);

const {
	productPages,
	isLoaded: isEcommerceProductsLoaded,
} = useEcommerceGlobal();

const {
	blocks: siteBlocks,
	elements: siteElements,
	pages: siteLanguagePages,
	siteId: websiteId,
	blogReadingTimeText,
	blogCategories,
	siteId,
	meta,
} = useSiteGlobal();

const {
	addIntersectionObserver,
	observe,
	intersectionObserver,
	animationClass,
	shouldMountAnimationsInPreview,
} = useSiteEngineAnimations({
	data: props.data,
});
const currentSiteLocale = computed(() => props.currentLocale);

const isDesktopBlockHidden = computed(() => {
	// these conditions solves the issue where on desktop hidden blocks are invisible in mobile's preview mode
	if (!props.data.desktop?.isHidden) {
		return false;
	}

	return (props.isInPreviewMode ? props.screenWidth > MEDIA_MOBILE_SCREEN_BREAKPOINT : true);
});

const isMobileBlockHidden = computed(() => {
	if (!props.data.mobile?.isHidden) {
		return false;
	}

	return (props.isInPreviewMode ? props.screenWidth <= MEDIA_MOBILE_SCREEN_BREAKPOINT : true);
});

const computedStyles = computed(() => ({
	...props.style,
	...objectToCssVariables(props.data?.settings?.styles),
}));
const isMobileFullScreen = computed(() => {
	if (!props.data?.settings?.styles['m-block-padding']) {
		return false;
	}

	/**
			 * This is an assumption that if user sets large paddings on mobile,
			 * he might want to have either full screen or height background.
			 */
	const {
		top,
		bottom,
	} = parseCSSSides(props.data.settings.styles['m-block-padding']);
	const mobilePadding = Number.parseInt(top, 10) + Number.parseInt(bottom, 10);

	return mobilePadding > MOBILE_FULL_HEIGT_THRESHOLD;
});

const backgroundSrc = computed(() => getOptimizedSrc(
	props.data.background.origin,
	props.data.background.path,
	siteId.value,
	{
		width: FULL_WIDTH,
	},
));

const backgroundSrcSet = computed(
	() => getFullWidthSrcset(
		props.data.background.origin,
		props.data.background.path,
		siteId.value,
		{
			isMobileFullScreen: isMobileFullScreen.value,
		},
	),
);

const isBlockFooter = computed(() => props.data.slot === 'footer');

const currentBlogPage = computed(() => {
	if (props.data.type !== BLOCK_TYPE_BLOG_HEADER) {
		return null;
	}

	const [, currentBlockPage] = Object.entries(siteLanguagePages.value).find(
		([pageId, page]) => page.type === PAGE_TYPE_BLOG && pageId === props.pageId,
	);

	return currentBlockPage;
});

const stripePublicApiKey = computed(() => meta.value.stripePublicApiKey);

const isEcommerceBlock = computed(() => props.data.type === BLOCK_TYPE_ECOMMERCE_PRODUCT_LIST);

const initiateAnimations = async () => {
	addIntersectionObserver();

	if (!isEcommerceBlock.value || isEcommerceProductsLoaded.value) {
		await observe(blockRef.value);
	}
};

// ecommerce products are loaded later so need to observe them after they are loaded
onMounted(async () => {
	await initiateAnimations();
});

onBeforeUnmount(() => intersectionObserver.value?.disconnect());

watch([
	currentSiteLocale,
	isEcommerceProductsLoaded,
], async ([currentLocale, isLoaded], [previousLocale]) => {
	if ((isLoaded || currentLocale !== previousLocale) && isEcommerceBlock.value) {
		await observe(blockRef.value);
	}
});

watch(shouldMountAnimationsInPreview, async (newValue) => {
	if (newValue) {
		await initiateAnimations();
	}
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/components/Block";
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.block--desktop-hidden {
	@media screen and (min-width: $media-mobile) {
		display: none;
	}
}

@include site-engine-mobile {
	.block--mobile-hidden {
		display: none;
	}
}
</style>
